import React from "react";

const SoftwareDevelopment = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 81 80"
      fill="none"
      className="mobile-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.75 44.25L61.75 35.5C61.5 35 61 35 60.5 35C60 35 59.5 35 59.25 35.25L44.25 44C43.5 44.5 43 45.25 43 46.25V63.5C43 64.5 43.5 65.25 44.25 65.75L59.25 74.5C59.5 75 60 75 60.5 75C61 75 61.5 75 61.75 74.75L76.75 66C77.5 65.5 78 64.75 78 63.75V46.5C78 45.5 77.5 44.75 76.75 44.25ZM73 62.25L60.5 69.75L48 62.25V47.75L60.5 40.25L73 47.75V62.25Z"
        fill="#004C3F"
      />
      <path
        d="M35.5 65H23C12 65 3 56 3 45C3 35.75 9.5 27.75 18.5 25.5C20.5 13.75 30.75 5 43 5C56.75 5 68 16.25 68 30H63C63 19 54 10 43 10C32.75 10 24.25 17.75 23 28V30L21 30.25C13.5 31.25 8 37.5 8 45C8 53.25 14.75 60 23 60H35.5V65Z"
        fill="#212121"
      />
    </svg>
  );
};

export default SoftwareDevelopment;
