import React from "react";

const Forecasting = () => {
  return (
    <div>
      <p>Forecasting</p>
    </div>
  );
};

export default Forecasting;
