import React from "react";

const NLP = () => {
  return (
    <div>
      <p>NLP</p>
    </div>
  );
};

export default NLP;
