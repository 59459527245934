import React from "react";

const HealIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.8 7.2C48.8 6.35131 48.4629 5.53737 47.8627 4.93726C47.2626 4.33714 46.4487 4 45.6 4H26.4C25.5513 4 24.7374 4.33714 24.1373 4.93726C23.5371 5.53737 23.2 6.35131 23.2 7.2V23.2H7.2C6.35131 23.2 5.53737 23.5371 4.93726 24.1373C4.33714 24.7374 4 25.5513 4 26.4V45.6C4 46.4487 4.33714 47.2626 4.93726 47.8627C5.53737 48.4629 6.35131 48.8 7.2 48.8H23.2V64.8C23.2 65.6487 23.5371 66.4626 24.1373 67.0627C24.7374 67.6629 25.5513 68 26.4 68H45.6C46.4487 68 47.2626 67.6629 47.8627 67.0627C48.4629 66.4626 48.8 65.6487 48.8 64.8V48.8H64.8C65.6487 48.8 66.4626 48.4629 67.0627 47.8627C67.6629 47.2626 68 46.4487 68 45.6V26.4C68 25.5513 67.6629 24.7374 67.0627 24.1373C66.4626 23.5371 65.6487 23.2 64.8 23.2H48.8V7.2Z"
        stroke="#004C3F"
        stroke-width="6.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HealIcon;
