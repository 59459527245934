import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ProductCard = (props) => {
  const isMobileScreen = useMediaQuery({ query: "(min-width: 480px)" });

  let navigate = useNavigate();
  return (
    <div
      className="productCard"
      style={{
        background: "linear-gradient(180deg, #38786A 0%, #002419 100%)",
        borderRadius: "6.3px",
        padding: "12px 12px 5px 12px",
        // minHeight: "100%",
      }}
      onClick={() => navigate("/our-projects/" + props.link)}
    >
      <img
        src={props.img}
        alt="img"
        style={{ height: "150px", width: "100%", borderRadius: "6.3px" }}
      />

      {isMobileScreen ? (
        <h6
          className="text-white pb-3"
          style={{ fontWeight: "700", fontSize: "15px", marginTop: "7pt" }}
        >
          {props.title}
        </h6>
      ) : (
        <div id="prd-card-title">
          <h6
            className="text-white"
            style={{ fontWeight: "700", fontSize: "15px" }}
          >
            {props.title}
          </h6>
        </div>
      )}

      <p className="text-white font-200" style={{ fontSize: "11px" }}>
        {props.desc}
      </p>
      {isMobileScreen && (
        <div style={{ textAlign: "end", marginTop: "5pt" }}>
          <a
            href={"/our-projects/" + props.link}
            className="text-white font-200 read-more"
            style={{ fontSize: "9px", textDecoration: "none" }}
          >
            Read more <BsArrowRightShort size={14} />
          </a>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
