import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

//image
import toolkit from "../../assets/images/image1.png";
import LicensePlate from "../../assets/images/licenseplate.png";
import SuggestionMining from "../../assets/images/suggestion.png";
import FalseRating from "../../assets/images/falserating.png";
import ChildAbuse from "../../assets/images/childabuse.png";
import StudentLoan from "../../assets/images/studentloan.png";
import CarPrice from "../../assets/images/car.jpeg";

//Icons
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const ProjectSlider = (props) => {
  const isMobileScreen = useMediaQuery({ query: "(min-width: 480px)" });

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowBack
        className={className}
        style={{
          ...style,
          display: "block",
          color: "grey",
          height: "100pt",
        }}
        onClick={onClick}
      />
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowForward
        className={className}
        style={{
          ...style,
          display: "block",
          color: "grey",
          height: "100pt",
        }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: isMobileScreen === true ? true : false,
    className: isMobileScreen === true ? "centerSlide" : "",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let navigate = useNavigate();

  return (
    <Slider {...settings}>
      {props.allProducts?.map((items, index) => {
        return (
          <div id="cards" style={{ width: "100%" }} key={index}>
            <div
              className="card-wrapper"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/our-projects/" + items._id)}
            >
              <img
                src={
                  "https://drive.google.com/uc?export=view&id=" + items.image
                }
                alt="img"
                // style={{ height: "130pt" }}
              />

              <h5
                className="font-700"
                style={{
                  marginTop: "12pt",
                  marginBottom: "8pt",
                  fontSize: "19px",
                }}
              >
                {items.title}
              </h5>

              <p className="font-300" style={{ fontSize: "12px" }}>
                {items.description}
              </p>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default ProjectSlider;
