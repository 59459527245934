import React from "react";

const AdvancedAnalytics = () => {
  return (
    <div>
      <p>AdvancedAnalytics</p>
    </div>
  );
};

export default AdvancedAnalytics;
