import React from "react";

const ResearchIcon = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mobile-icon"
    >
      <g clipPath="url(#clip0_431_954)">
        <path
          d="M28 10C23.3587 10 18.9075 11.8437 15.6256 15.1256C12.3437 18.4075 10.5 22.8587 10.5 27.5V52.5C10.5 57.1413 12.3437 61.5925 15.6256 64.8744C18.9075 68.1563 23.3587 70 28 70H53C57.6413 70 62.0925 68.1563 65.3744 64.8744C68.6563 61.5925 70.5 57.1413 70.5 52.5V40C70.5 39.337 70.7634 38.7011 71.2322 38.2322C71.7011 37.7634 72.337 37.5 73 37.5C73.663 37.5 74.2989 37.7634 74.7678 38.2322C75.2366 38.7011 75.5 39.337 75.5 40V52.5C75.5 58.4674 73.1295 64.1903 68.9099 68.4099C64.6903 72.6295 58.9674 75 53 75H28C22.0326 75 16.3097 72.6295 12.0901 68.4099C7.87053 64.1903 5.5 58.4674 5.5 52.5V27.5C5.5 21.5326 7.87053 15.8097 12.0901 11.5901C16.3097 7.37053 22.0326 5 28 5H40.5C41.163 5 41.7989 5.26339 42.2678 5.73223C42.7366 6.20107 43 6.83696 43 7.5C43 8.16304 42.7366 8.79893 42.2678 9.26777C41.7989 9.73661 41.163 10 40.5 10H28Z"
          fill="#212121"
        />
        <path
          d="M80.5 15C80.5 18.9782 78.9196 22.7936 76.1066 25.6066C73.2936 28.4196 69.4782 30 65.5 30C61.5218 30 57.7064 28.4196 54.8934 25.6066C52.0804 22.7936 50.5 18.9782 50.5 15C50.5 11.0218 52.0804 7.20644 54.8934 4.3934C57.7064 1.58035 61.5218 0 65.5 0C69.4782 0 73.2936 1.58035 76.1066 4.3934C78.9196 7.20644 80.5 11.0218 80.5 15Z"
          fill="#004C3F"
        />
      </g>
      <defs>
        <clipPath id="clip0_431_954">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResearchIcon;
