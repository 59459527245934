import React from "react";

const DollarIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.4 28C32.8 27.6 31.2 26.8 30 25.6C28.8 25.2 28.4 24 28.4 23.2C28.4 22.4 28.8 21.2 29.6 20.8C30.8 20 32 19.2 33.2 19.6C35.6 19.6 37.6 20.8 38.8 22.4L42.4 17.6C41.2 16.4 40 15.6 38.8 14.8C37.6 14 36 13.6 34.4 13.6V8H29.6V13.6C27.6 14 25.6 15.2 24 16.8C22.4 18.8 21.2 21.2 21.6 23.6C21.6 26 22.4 28.4 24 30C26 32 28.8 33.2 31.2 34.4C32.4 34.8 34 35.6 35.2 36.4C36 37.2 36.4 38.4 36.4 39.6C36.4 40.8 36 42 35.2 43.2C34 44.4 32.4 44.8 31.2 44.8C29.6 44.8 27.6 44.4 26.4 43.2C25.2 42.4 24 41.2 23.2 40L19.2 44.4C20.4 46 21.6 47.2 23.2 48.4C25.2 49.6 27.6 50.8 30 50.8V56H34.4V50C36.8 49.6 38.8 48.4 40.4 46.8C42.4 44.8 43.6 41.6 43.6 38.8C43.6 36.4 42.8 33.6 40.8 32C38.8 30 36.8 28.8 34.4 28ZM32 0C14.4 0 0 14.4 0 32C0 49.6 14.4 64 32 64C49.6 64 64 49.6 64 32C64 14.4 49.6 0 32 0ZM32 59.6C16.8 59.6 4.4 47.2 4.4 32C4.4 16.8 16.8 4.4 32 4.4C47.2 4.4 59.6 16.8 59.6 32C59.6 47.2 47.2 59.6 32 59.6Z"
        fill="#004C3F"
      />
    </svg>
  );
};

export default DollarIcon;
