import React from "react";

const ContactUs = () => {
  return (
    <div>
      <p>ContactUs</p>
    </div>
  );
};

export default ContactUs;
